@font-face {
        font-family: "Sora-Bold";
        src: local("Sora-Bold"),
        url("./fonts/Sora-Bold.ttf") format("truetype");
    }

@font-face {
        font-family: "Sora-ExtraBold";
        src: local("Sora-ExtraBold"),
            url("./fonts/Sora-ExtraBold.ttf") format("truetype");
    }

@font-face {
    font-family: "Sora-ExtraLight";
    src: local("Sora-ExtraLight"),
        url("./fonts/Sora-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: "Sora-Light";
    src: local("Sora-Light"),
        url("./fonts/Sora-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Sora-Medium";
    src: local("Sora-Medium"),
        url("./fonts/Sora-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Sora-Regular";
    src: local("Sora-Regular"),
        url("./fonts/Sora-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Sora-SemiBold";
    src: local("Sora-SemiBold"),
        url("./fonts/Sora-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Sora-Thin";
    src: local("Sora-Thin"),
        url("./fonts/Sora-Thin.ttf") format("truetype");
}
.title-container {
    .title {
        text-align: center;
        font-family: "Sora-Bold";
    }
}


h3 {
    font-family: "Sora-Bold";
}

h4 {
    font-family: "Sora-Bold";
}

p,a {
    font-family: "Sora-Regular";
}

.home-container {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 768px) {
        display: inline-block;
    }

    .home-description-container {
        vertical-align: middle;
        
        .description {
            font-family: "Sora-Regular";
            text-align: center;
            padding: 1rem;
        }
    
    }
}
.footer{

    .links {
        text-align: center;
        position: fixed;
        bottom: 0.25rem;
        left: 0.5rem;
    }

    img {
        height: 2rem;
        padding-right: 0.5rem;
    }

    .built-tag {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
    }
}

img {
    &.home-photo {
        float:left;
        width: 20%;
    }
    &.contact-photo {
        max-width: 100%;
        height: auto;
    }
    &.mobile-photo {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        &.home-photo {
            display: none;
        }
        &.mobile-photo {
            display: block;
            width: 100%;
        }
    }
}

.navbar {
    background: #F4A261;
    a {
        font-family: "Sora-ExtraBold";
    }
    overflow: hidden;

    .mobile-nav {
        display: none;
    }

    @media only screen and (max-width: 768px) {
        .mobile-nav {
            display: block;
            margin: 0 auto;

            .mobile-logo {
                    top: 0;
                    font-family: "Sora-Bold";
                    font-size: 3rem;
            }
        }
    }

    .home-nav,
    .aboutme-nav,
    .contact-nav,
    .resume-nav {
        color: #FFFFFF;
        &.active,
        &:hover {
            border-bottom: 5px solid lightgrey;
          }
    }

    .home-nav {
        background: #264653;
        border-bottom: 5px solid #264653;
    }

    .aboutme-nav {
        background: #2A9D8F;
        border-bottom: 5px solid #2A9D8F;
    }

    .resume-nav {
        background: #E9C46A;
        border-bottom: 5px solid #E9C46A;
    }

    .contact-nav {
        background: #F4A261;
        border-bottom: 5px solid #F4A261;
    }

    .signin-nav {
        background: #E76F51;
    }

    .navitem {

    }
}

td, tr {
    padding: 0;
    p {
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
    }
    .book-author {
        padding-right: 0.2rem;
    }
}
.book-container {
    padding-left: 4rem;
    @media only screen and (max-width: 768px) {
        padding-left: 1rem;
        font-size: 12px;
    }
}

.general-books,
.leadership-books,
.explicit-books {
    h3,
    .subtitle {
        padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 0.5rem;

        @media only screen and (max-width: 768px) {
            padding-left: 1rem;
        }
    }
    .subtitle {
        color: maroon;
        margin-top: 0;
        padding-left: 3rem;
        @media only screen and (max-width: 768px) {
            padding-left: 1rem;
        }
    }
}

.contact-title {
    text-align: center;
}
.contact-email {
    text-align: center;
    padding: 1rem;
}

.resume-container {
    .react-pdf__Page {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .react-pdf__Page__canvas {

        margin: 0 auto;
        border: lightgrey solid 1px;
      }
    .rpv-core__viewer {

    }
}